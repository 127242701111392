import Layout from 'components/layout';
import Theme from 'components/template/Theme';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import history from './history';
import store, { persistor } from './store';
// import appConfig from 'configs/app.config'
import './locales';

// const environment = process.env.NODE_ENV

localStorage.setItem('env', 'prod');

const App = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter history={history}>
				<Theme>
					<Layout />
				</Theme>
			</BrowserRouter>
		</PersistGate>
	</Provider>
);

export default App;
