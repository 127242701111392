import {
  BaseService,
  InfluencerBaseService,
  PlatformBaseService,
} from './BaseService';

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
  updateData(param) {
    return new Promise((resolve, reject) => {
      BaseService({
        method: 'POST',
        headers: {},
      })
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
};

const PlatFormService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      PlatformBaseService(param)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
  updateData(param) {
    return new Promise((resolve, reject) => {
      PlatformBaseService({
        method: 'POST',
        headers: {},
      })
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
};

const InfluencerService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      InfluencerBaseService(param)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
  updateData(param) {
    return new Promise((resolve, reject) => {
      InfluencerBaseService({
        method: 'POST',
        headers: {},
      })
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
};

export { ApiService, InfluencerService, PlatFormService };
