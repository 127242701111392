import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiSignIn, apiSignUp } from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import { initialState, setUser } from 'store/auth/userSlice';
import useQuery from './useQuery';

function useAuth() {
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const query = useQuery();

	const { token, signedIn } = useSelector((state) => state.auth.session);

	const signIn = async (values) => {
		try {
			const resp = await apiSignIn(values);
			if (resp.data) {
				const { user, token } = resp.data;
				dispatch(onSignInSuccess(token));
				if (user) {
					dispatch(
						setUser({
							avatar: user.avatar ? user.avatar : '',
							userName: user.userName ? user.userName : 'Anonymous',
							authority: user.roles ? user.roles : ['USER'],
							email: user.email ? user.email : '',
						})
					);
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
				return {
					status: 'success',
					message: '',
				};
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const signUp = async (values) => {
		const { userName, password, email } = values;
		try {
			const resp = await apiSignUp({ password, email });
			if (resp.data) {
				const { user, token } = resp.data;
				dispatch(onSignInSuccess(token));
				if (user) {
					dispatch(
						setUser({
							avatar: '',
							userName: user.userName ? user.userName : 'Anonymous',
							authority: ['USER'],
							email: user.email ? user.email : '',
						})
					);
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
				return {
					status: 'success',
					message: '',
				};
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const handleSignOut = () => {
		dispatch(onSignOutSuccess());
		dispatch(setUser(initialState));
		navigate(appConfig.unAuthenticatedEntryPath);
	};

	const signOut = async () => {
		// await apiSignOut()
		handleSignOut();
	};

	return {
		authenticated: token && signedIn,
		signIn,
		signUp,
		signOut,
	};
}

export default useAuth;
