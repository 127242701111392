import { Notification, toast } from 'components/ui';
import { InfluencerService } from 'services/ApiService';

const fetchVideos = async (filters = {}) => {
  try {
    const response = await InfluencerService.fetchData({
      url: '/admin/get-influencer-media',
      params: filters,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
const fetchVideo = async (media_id) => {
  try {
    const response = await InfluencerService.fetchData({
      url: '/admin/get-influencer-media',
      params: {
        media_id,
      },
    });

    return response.data.posts[0];
  } catch (error) {
    console.log(error);
  }
};
const fetchInfluencers = async (filters) => {
  try {
    const response = await InfluencerService.fetchData({
      url: '/admin/get-all-influencers',
      params: filters,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
const syncMedia = async (params = {}) => {
  try {
    const response = await InfluencerService.fetchData({
      url: '/admin/sync-influencer-media',
      params: {
        ...params,
        force_process_media: true,
      },
    });
    toast.push(
      <Notification
        type={'success'}
        title={`${response.data.message.toUpperCase()}`}
      />,
      {
        placement: 'top-center',
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.push(
      <Notification
        type="danger"
        title={`${error.response.data.err.toUpperCase()}`}
      />,
      {
        placement: 'top-center',
      }
    );
  }
};
const reviewMedia = async (payload) => {
  try {
    const response = await InfluencerService.fetchData({
      url: '/admin/review-media',
      method: 'post',
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
const boostMedia = async (payload) => {
  try {
    const response = await InfluencerService.fetchData({
      url: '/admin/boost-media',
      method: 'post',
      data: payload,
    });
    toast.push(
      <Notification
        type={'success'}
        title={`${response.data.message.toUpperCase()}`}
      />,
      {
        placement: 'top-center',
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
const addProduct = async (payload) => {
  try {
    const response = await InfluencerService.fetchData({
      url: '/admin/add-product-to-media',
      method: 'post',
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
const removeProduct = async (payload) => {
  try {
    const response = await InfluencerService.fetchData({
      url: '/admin/remove-product-from-media',
      method: 'post',
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const VideoTaggingService = {
  fetchVideo,
  fetchVideos,
  reviewMedia,
  boostMedia,
  addProduct,
  removeProduct,
  fetchInfluencers,
  syncMedia,
};

export default VideoTaggingService;
