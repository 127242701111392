import { ApiService } from "./ApiService";

const baseEndPoint = "product_enrichment";
export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: baseEndPoint + "/login",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: baseEndPoint + "/signup",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: baseEndPoint + "/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: baseEndPoint + "/forgot-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: baseEndPoint + "/reset-password",
    method: "post",
    data,
  });
}
