const appConfig = {
  searchApiPrefix: 'https://reco.dpanda.in/toolbox-search', // to be changed later
  deployApiPrefix: 'https://reco.dpanda.in', // to be changed later
  // platformApiPrefix: process.env.REACT_APP_PLATFORM_API,
  platformApiPrefix: 'https://app.dpanda.in/index.php',
  influencerApiPrefix: 'https://influencer.dpanda.in',
  apiPrefix: 'https://reco.dpanda.in/toolbox',
  authenticatedEntryPath: '/home',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
};

export default appConfig;
