import {createSlice} from "@reduxjs/toolkit";

export const publisherJsonSlice = createSlice({
    name: "publisherJson",
    initialState : {
        configUrl: null,
        filters: {start: 0, limit: 10, key: null},
        publisherId: null
    },
    reducers: {
        setJson: (state,action) => {
            state.configUrl = action.payload.configUrl
            state.publisherId = action.payload.publisherId
        },
        setFilters: (state, action) => {
            state.filters = action.payload.filters
        },
        unsetJson: (state, action) => {
            state = state.initialState
        }
    }
})

export const {setJson, setFilters,unsetJson} = publisherJsonSlice.actions

export default publisherJsonSlice.reducer